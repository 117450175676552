import React, { ReactElement, useContext } from 'react';
import { UserCirclePlus } from '@phosphor-icons/react';
import { AuthorisationContext } from 'services';
import { generateMenuList } from './utils';
import { DropdownWithCheckbox } from 'components';

export type PickBusinessesProps = {
  selectedBusinesses: unknown[];
  handleSelectBusiness: (id: string | string[]) => void;
};

export const PickBusinesses = ({ handleSelectBusiness }: PickBusinessesProps): ReactElement => {
  const { user } = useContext(AuthorisationContext);

  const convertedData = user?.businesses.map((item) => ({ ...item, id: item.id.toString() })) || [];

  return (
    <DropdownWithCheckbox
      items={generateMenuList(convertedData || [])}
      selected={user!.businesses.map((value) => value.id.toString())}
      handler={handleSelectBusiness}
      btnIcon={<UserCirclePlus />}
      btnText="Pick businesses"
    />
  );
};
