import { useEffect, useMemo, useState } from 'react';
import { SegmentedValue } from 'antd/es/segmented';
import dayjs, { Dayjs } from 'dayjs';
import {
  CardTitles,
  CardTitlesEnum,
  CurrencyEnum,
  DATE_FORMAT_FULL,
  DEFAULT_DATE_RANGE,
  IStatistics,
  SegmentedDateEnum,
} from 'models';
import { useBusinessStatisticsQuery } from 'services';
import { useHandleState } from 'hooks';
import { useSelectMenuItems } from '../DropdownWithCheckbox/hooks/useMenu';
import { countIncome } from 'utils';
import { generateStatisticCardMap } from './utils';
import { generateDateAmount } from '../SegmentedDate/generateDateAmount';
import {
  CardNew,
  OverviewSettingsButton,
  PickBusinesses,
  ProfitCard,
  RangePicker,
  ReduceToCurrency,
  SegmentedDate,
} from 'components';
import Empty from 'components/common/Empty/Empty';
import styles from './BusinessOverview.module.scss';

export const BusinessOverview = ({ businessId }: { businessId?: number; }) => {
  const { selected: selectedBusinesses, selectHandler: setSelectedBusinesses } =
    useSelectMenuItems(businessId);
  const [selectedCurrency, setSelectedCurrency] = useState(CurrencyEnum.USDT);
  const [segmented, handleSegmented] = useHandleState<SegmentedValue>(
    SegmentedDateEnum.WEEK,
  );
  const [isCustomDate, handleCustomDate] = useState(false);
  const [isReduced, setReduced] = useState(false);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    dayjs().utc().startOf('day').subtract(DEFAULT_DATE_RANGE, 'day'),
    dayjs().utc().startOf('day').add(1, 'day').subtract(1, 'second'),
  ]);
  const { selectHandler: selectCardsHandler, selected: selectedCards } =
    useSelectMenuItems();
  const onDateRangeChangeHandler = (range: [Dayjs | null, Dayjs | null]) => {
    setDateRange(range);
  };
  const params = useMemo(
    () => ({
      filter: {
        and: {
          '0': {
            createdAt: {
              between: {
                lower: dayjs(dateRange[0]).format(DATE_FORMAT_FULL),
                upper: dayjs(dateRange[1]).format(DATE_FORMAT_FULL),
              },
            },
          },
          ...(!isReduced
            ? {
              '1': {
                currency: {
                  eq: selectedCurrency,
                },
              },
            }
            : {}),
        },
      },
    }),
    [selectedCurrency, dateRange, isReduced],
  );

  const { data, isLoading, isError, refetch } = useBusinessStatisticsQuery({
    ids: selectedBusinesses,
    currency: selectedCurrency,
    params,
  });

  useEffect(() => {
    refetch();
  }, [isReduced, selectedBusinesses, selectedCurrency, refetch]);

  if (isError) return <Empty />;

  const cards = [...Object.keys(CardTitles)];
  const showProfitCard =
    selectedCards.length > 0 && selectedCards.includes(CardTitlesEnum.PROFIT);
  const generatedDate = generateDateAmount(
    segmented as SegmentedDateEnum,
  ) as number;
  const profit = (countIncome(data as IStatistics, selectedCurrency) - (data as IStatistics)?.totalsAmounts?.expenses) ?? 0;
  const filteredCards = cards.slice(1).filter((k) => selectedCards.includes(k));

  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerWrapper}>
          <SegmentedDate
            handleCustomDate={handleCustomDate}
            handleSegmented={handleSegmented}
            isCustomDate={isCustomDate}
            segmented={segmented}
          />
          <RangePicker
            handler={onDateRangeChangeHandler}
            values={dateRange}
            daysBefore={generatedDate}
            isDisabled={!isCustomDate}
          />
        </div>
        <div className={styles.headerWrapper}>
          <ReduceToCurrency
            currency={selectedCurrency}
            currencyHandler={setSelectedCurrency}
            reduceHandler={setReduced}
          />
          <div className={styles.innerHeaderWrapper}>
            {!businessId && (
              <PickBusinesses
                handleSelectBusiness={setSelectedBusinesses}
                selectedBusinesses={selectedBusinesses}
              />
            )}
            <OverviewSettingsButton
              selected={selectedBusinesses}
              handler={selectCardsHandler}
            />
          </div>
        </div>
      </div>

      {!data || !isLoading && !selectedCards.length && <Empty image={Empty.PRESENTED_IMAGE_BUSINESS} />}

      <div className={styles.body}>
        <div className={styles.profitCard}>
          {showProfitCard && (
            <ProfitCard
              data={data as IStatistics}
              title={CardTitlesEnum.PROFIT}
              amount={profit}
              currency={selectedCurrency}
              dateRange={dateRange}
              isLoading={isLoading}
            />
          )}
        </div>
        <div className={styles.cardList}>
          {filteredCards.map((key) => {
            return (
              <CardNew
                key={key}
                amount={generateStatisticCardMap(data!, selectedCurrency, key) as number}
                data={data as IStatistics}
                title={key as CardTitlesEnum}
                currency={selectedCurrency}
                dateRange={dateRange}
                isLoading={isLoading}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
